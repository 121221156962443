<template>
  <div class="login-container">
    <v-card class="login-form">
        <DialogHeader icon="mdi-login" text="Login"></DialogHeader>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-alert
              v-if="authError"
              dense
              text
              type="error"
            >{{authError}}</v-alert>

            <v-text-field
              label="Email"
              placeholder="Provide your email"
              v-model="email"
              :rules="formRules.emailRules"
              required
            ></v-text-field>

            <v-text-field
              label="Password"
              type="password"
              placeholder="Provide your password"
              v-model="password"
              :rules="formRules.passwordRules"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-toolbar>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            type="submit"
            @click.prevent="onSubmit"
          >
            Login
          </v-btn>
        </v-toolbar>
    </v-card>
  </div>
</template>

<script>
import router from '@/router';
import { AuthTypes, AUTH_STORE } from '@/store/auth';
import { ref, reactive } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import DialogHeader from '@/components/DialogHeader.vue';

const { useState, useActions } = createNamespacedHelpers(AUTH_STORE);

export default {
  name: 'Login',
  components: { DialogHeader },
  setup() {
    const form = ref(null);
    const valid = ref(false);
    const email = ref('');
    const password = ref('');

    const formRules = reactive({
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
    });

    const { authenticate } = useActions([AuthTypes.actions.authenticate]);
    const { authError } = useState([AuthTypes.state.authError]);

    async function onSubmit() {
      form.value.validate();
      if (valid.value) {
        const result = await authenticate({ email: email.value, password: password.value });
        if (result === AuthTypes.notifications.AUTHENTICATE_SUCCESS) {
          router.push({ name: 'Clients' });
        } else if (result === AuthTypes.notifications.AUTHENTICATE_NEW_PASSWORD) {
          router.push({ name: 'NewPassword', params: { email } });
        }
      }
    }

    return {
      form,
      valid,
      formRules,
      authError,
      email,
      password,
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .login-form {
      width: 600px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }
</style>
